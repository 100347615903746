import React from "react";
import etapas from "../images/etapas.png";
import "./fourthSection.css";

const FourthSection = () => (
  <section className="yellow-bg" id="etapas">
    <div className="container">
      <h2 className="text-blue">ETAPAS DE AQUISIÇÃO</h2>
      <div className="row align-items-center justify-content-between o-hidden">
        <div className="col-lg-6 mb-5 mb-lg-0 d-flex justify-content-center order-lg-2">
          <img
            src={etapas}
            className="img-fluid etapas-img mb-0"
            alt="Fluxo de etapas de aquisição do Home Equity"
          />
        </div>
        <div className="col-lg-6 order-lg-1">
          <div className="d-flex mb-1 mb-lg-4">
            <div className="process-circle mt-2"></div>
            <div className="ml-3 font-weight-medium">
              <p>
                Você faz uma <span className="text-decor">solicitação</span> de
                análise de crédito pelo nosso site, whatsapp ou outro canal de
                atendimento e nossa equipe entra em contato com as melhores
                propostas de acordo com sua necessidade.
              </p>
            </div>
          </div>
          <div className="d-flex mb-1 mb-lg-4">
            <div className="process-circle mt-2"></div>
            <div className="ml-3 font-weight-medium">
              <p>
                Para aderir, basta ter um{" "}
                <span className="text-decor">imóvel</span> em seu nome
                (residencial ou comercial) e quitado. O dinheiro cai diretamente
                em sua conta e você pode utilizá-lo como quiser.
                {/* No empréstimo com imóvel em garantia, o dinheiro cai na sua conta em média em 30 dias após
                    sua solicitação. Esse prazo pode variar de acordo com os cartórios da sua região. */}
              </p>
            </div>
          </div>
          <div className="d-flex mb-1 mb-lg-4">
            <div className="process-circle mt-2"></div>
            <div className="ml-3 font-weight-medium">
              <p>
                O prazo para a{" "}
                <span className="text-decor">liberação do crédito</span> leva,
                em média, 30 dias. O prazo pode variar de acordo com os
                cartórios de sua região. Com a Guerra, você não paga nenhuma
                taxa de adiantamento ou de análise de crédito.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FourthSection;
