import React, { useState } from "react";
import "./mainSection.scss";
import "../../node_modules/sal.js/dist/sal.css";

import ContactModal from "./contactModal";

function Ms() {
  const [modalShow, setModalShow] = useState(false);




  return (
    <section className="MS-wrapper">
      <div className="MS-content">
        <div className="MS-title-container">
          <h1 id="home" className="display-big">
            <span className="first-Word">EMPRÉSTIMO</span>
              <br />
            <span className="main-highlight">COM </span>
              GARANTIA
            </h1>
        </div>
        <div className="MS-sub-btn-container">
          <p className="lead">
            Dinheiro direto na sua conta, para usar como quiser, com as
            melhores taxas do mercado e seu imóvel como garantia.
            </p>
          <button
            className="btn btn-lg btn-primary mb-sm-4"
            onClick={() => setModalShow(true)}
          >
            Simule agora
            </button>
        </div>
        <div className="divide"></div>
      </div>
      <ContactModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default Ms;
