import React, { useState } from "react";
import taxas from "../images/taxes_chart.png";
import "./secondSection.css";

import ContactModal from "./contactModal";

function SecondSection() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <section id="porque" className="second-bg pb-lg-0">
      <div className="container">
        <h2 className="text-black">POR QUE USAR O IMÓVEL OU VEÍCULO COMO GARANTIA?</h2>
        <p>
          Ao utilizar o seu imóvel ou veículo como garantia, além de continuar em seu nome
          e sob sua propriedade, você tem acesso a taxas diferenciadas por
          oferecer uma garantia real à instituição financeira.
          <br />
          Veja a comparação abaixo:
        </p>
        <img
          src={taxas}
          className="img-fluid taxes-img mb-5"
          alt="Fluxo de etapas de aquisição do Home Equity"
        />
        <div className="row mb-5">
          <div className="col">
            <div className="card card-cartas border-0 o-hidden mb-0 blue-bg text-light">
              <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div className="h3 text-center font-weight-medium mb-md-0">
                  Quer as melhores taxas do mercado?
                </div>
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => setModalShow(true)}
                >
                  <span>Faça uma simulação</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider blue-bg"></div>
      <ContactModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default SecondSection;
