import React, { Component } from "react";
import { Accordion, Card } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./fifthSection.css";

let initialAnimation = [0, 0, 0, 0, 0];
export default class fifthSection extends Component {
  state = {
    animation: initialAnimation
  };

  hand(key) {
    if (initialAnimation[key] == 1) {
      console.log("Call == 1");
      initialAnimation[key] = 0;
      this.setState({ animation: initialAnimation });
    } else {
      console.log("Call == 0");
      initialAnimation[key] = 1;
      this.setState({ animation: initialAnimation });
    }
    console.log(
      "hand >>",
      this.state.animation,
      "key >>",
      key,
      "initialAnimation >>",
      initialAnimation
    );
  }

  render() {
    const { animation } = this.state;
    return (
      <section className="blue-bg text-yellow" id="duvidas">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <h2 className="mb-lg-0">DÚVIDAS FREQUENTES</h2>
            </div>
            <div className="col-lg-6 text-dark">
              <Accordion>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => this.hand(0)}
                  >
                    {animation[0] == 1 ? (
                      <div className="accordion-flex">
                        <p>O imóvel precisa estar 100% averbado?</p>
                        <FaAngleUp color="#000" />
                      </div>
                    ) : (
                      <div className="accordion-flex">
                        <p>O imóvel precisa estar 100% averbado?</p>
                        <FaAngleDown color="#000" />
                      </div>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      Se não estiver 100% averbado, será considerado o valor de
                      mercado com base na documentação apresentada. É necessário
                      possuir escritura do imóvel.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => this.hand(1)}
                  >
                    {animation[1] == 1 ? (
                      <div className="accordion-flex">
                        <p>Eu preciso desocupar o meu imóvel?</p>
                        <FaAngleUp color="#000" />
                      </div>
                    ) : (
                      <div className="accordion-flex">
                        <p>Eu preciso desocupar o meu imóvel?</p>
                        <FaAngleDown color="#000" />
                      </div>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      Não. Você pode continuar utilizando o imóvel da forma que
                      desejar. Ele continuará sob sua propriedade, será apenas
                      acrescentada uma alienação à sua matrícula.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => this.hand(2)}
                  >
                    {animation[2] == 1 ? (
                      <div className="accordion-flex">
                        <p>Preciso realizar algum pagamento na contratação?</p>
                        <FaAngleUp color="#000" />
                      </div>
                    ) : (
                      <div className="accordion-flex">
                        <p>Preciso realizar algum pagamento na contratação?</p>
                        <FaAngleDown color="#000" />
                      </div>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      Não. Você não paga nada até a aprovação de seu crédito.
                      Todos os custos estão incluídos na operação ou são
                      despesas de cartório.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => this.hand(3)}
                  >
                    {animation[3] == 1 ? (
                      <div className="accordion-flex">
                        <p>Tenho algum desconto se quitar antes do prazo?</p>
                        <FaAngleUp color="#000" />
                      </div>
                    ) : (
                      <div className="accordion-flex">
                        <p>Tenho algum desconto se quitar antes do prazo?</p>
                        <FaAngleDown color="#000" />
                      </div>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      Sim. Será calculado de acordo com a tabela da sua operação
                      (SAC ou PRICE), prazo remanescente e saldo devedor.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <Accordion>
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => this.hand(4)}
                  >
                    {animation[4] == 1 ? (
                      <div className="accordion-flex">
                        <p>Posso vender o imóvel que deixei como garantia?</p>
                        <FaAngleUp color="#000" />
                      </div>
                    ) : (
                      <div className="accordion-flex">
                        <p>Posso vender o imóvel que deixei como garantia?</p>
                        <FaAngleDown color="#000" />
                      </div>
                    )}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      Sim. No entanto, antes de vender, é necessário substituir
                      a garantia da operação ou quitá-la por completo.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// function FifthSection() {

//   let initialAnimation = [0, 0, 0, 0, 0]

//   const [animation, setAnimation] = useState(initialAnimation);

//   const hand = key => e => {
//     if (initialAnimation[key] == 1) {
//       console.log('Call == 1')
//       initialAnimation[key] = 0
//       setAnimation(initialAnimation)
//     } else {
//       console.log('Call == 0')
//       initialAnimation[key] = 1
//       setAnimation(initialAnimation)
//     }
//     console.log('hand >>', animation, 'key >>', key, 'initialAnimation >>', initialAnimation)
//   }
// }

// export default FifthSection;
