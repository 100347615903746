import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSection from "../components/mainSection";
import SecondSection from "../components/secondSection";
import ThirdSection from "../components/thirdSection";
import FourthSection from "../components/fourthSection";
import FifthSection from "../components/fifthSection";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Empréstimo com Garantia"
      description="
      Empréstimo com garantia de imóvel. Dinheiro direto na sua conta, para usar como quiser, 
      com as melhores taxas do mercado e seu imóvel como garantia. 
      Consultores especializados, clique e simule."
    />
    <MainSection />
    <SecondSection />
    <ThirdSection />
    <FourthSection />
    <FifthSection />
  </Layout>
);

export default IndexPage;
