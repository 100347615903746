import React from "react";
import rapido from "../images/icon1.png";
import simplificado from "../images/icon2.png";
import omnichannel from "../images/icon3.png";
import seguro from "../images/icon6.png";
import tecnologico from "../images/icon5.png";
import humanizado from "../images/icon4.png";
import "../../node_modules/sal.js/dist/sal.css";
import "./thirdSection.css";

const ThirdSection = () => (
  <section id="icones" className="third-bg text-white">
    <div className="container">
      <h2>VANTAGENS</h2>
      <div className="row">
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={rapido}
              className="card-img"
              alt="Ícone do card que explica rapidez do processo"
            />
            <h3 className="card-title">Livre</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                A Liberação do crédito ocorre em sua conta e você pode
                utilizá-lo para a finalidade que desejar.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={simplificado}
              className="card-img"
              alt="Ícone do card que explica simplificação do processo"
            />
            <h3 className="card-title">Barato</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                Melhores condições do mercado para crédito pessoal graças ao
                imóvel como garantia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={humanizado}
              className="card-img"
              alt="Ícone do card que explica a comunicação do processo"
            />
            <h3 className="card-title">Flexível</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                De 60 até 180 meses para pagar. Você também pode optar pela
                tabela SAC ou PRICE, o que for melhor para você.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={seguro}
              className="card-img"
              alt="Ícone do card que explica a segurança do processo"
            />
            <h3 className="card-title">Seguro</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                O imóvel continua sob sua propiedade e em seu nome. Será apenas
                acrescentado uma alienação à sua matrícula.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={tecnologico}
              className="card-img"
              alt="Ícone do card que explica a tecnologia do processo"
            />
            <h3 className="card-title">Aprovação Facilitada</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                Você pode compor renda com ate 3 pessoas e utilizar inclusive
                lotes como garantia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-4 d-flex mb-2 mb-md-4">
          <div className="card card-body align-items-center text-center text-dark">
            <img
              src={omnichannel}
              className="card-img"
              alt="Ícone do card que explica que o processo é humanizado"
            />
            <h3 className="card-title">Atendimento</h3>
            <div className="flex-grow-1">
              <p className="card-text">
                Você contará com um consultor especializado para te acompanhar
                em todo o processo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ThirdSection;
